

function PublicHome() {

    return (
        <div className="App">
            <header className="App-header">
                <p>
                   Please Login.
                </p>
                

            </header>
        </div>
    );
}
export default PublicHome;