import React, {useEffect} from 'react';

function PrivateHome({user}) {

    const [name, setName] = React.useState('');
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        if(user && user?.userDetails){
            setName(user?.userDetails);  
        }
      }, [user]);

    const getDataFromApi = async (e: any) => {
        e.preventDefault();
        const data = await fetch(`/api/CountEmails?UPN=${name}&code=OIPGuvfalnjlAbAxxiKmjY7VMmuNiju17G3H6BSs3eDLAzFunX6okQ==`);
        const json = await data.json();

        if (json.message) {
            setMessage(json.message);
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Quick Example</h3>
              </div>
              <form>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email"></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">File input</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" id="exampleInputFile"></input>
                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text">Upload</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
                    <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                  </div>
                </div>
              

                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
                <form id="form1" className="App-form" onSubmit={e => getDataFromApi(e)}>
          <div>
            

            <h6>Initiate Full Backup</h6>
            <input 
              type="text" 
              id="name" 
              className="App-input" 
              placeholder="username@domain.com" 
              onChange={e=>setName(e.target.value)} />
            <button type="submit" className="App-button">Run Now</button>
          </div>
        </form>
                <div><h5>Message: {message} </h5></div>
            </header>

        </div>
    );
}
export default PrivateHome;