import { useState, useEffect } from 'react';
import './App.css';
import './adminlte.min.css';

// Custom components
import NavBar from './components/NavBar';
import PublicHome from './components/PublicHome';
import PrivateHome from './components/PrivateHome';

function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {

        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        
        if(clientPrincipal){
          setUser(clientPrincipal);
          userHasAuthenticated(true);
          console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
        } 
        
    } catch (error:any) {
        console.error('No profile could be found ' + error?.message?.toString());
    }
};  

  return (
    <div className="App">
      <NavBar user={user}/>
      <main className="column">
     
  <aside className="main-sidebar sidebar-dark-primary elevation-4">
    
    <a href="index3.html" className="brand-link">
      
      <span className="brand-text font-weight-light">Backup Admin</span>
    </a>

    
    <div className="sidebar">
     
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          
        </div>
        <div className="info">
          <a href="/" className="d-block">Alexander Pierce</a>
        </div>
      </div>

      
      <div className="form-inline">
        <div className="input-group" data-widget="sidebar-search">
          <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search"></input>
          <div className="input-group-append">
            <button className="btn btn-sidebar">
              <i className="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>

      
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item menu-open">
            <a href="/" className="nav-link active">
              <i className="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Starter Pages
                <i className="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="/" className="nav-link active">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Active Page</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Inactive Page</p>
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a href="/" className="nav-link">
              <i className="nav-icon fas fa-th"></i>
              <p>
                Simple Link
                <span className="right badge badge-danger">New</span>
              </p>
            </a>
          </li>
        </ul>
      </nav>
   
    </div>
  
  </aside>
        { isAuthenticated ? <PrivateHome user={user}/> : <PublicHome /> }
      </main>
    </div>
  )
}

export default App;