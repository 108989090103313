const NavBar = ({user}) => {

    const providers = ['aad'];

    return (
        <>
        {!user && providers.map((provider) => (
            <span>Backup Admin <a key={provider} href={`/login`}>Login</a> </span>
        ))}
        {user && (
            <div>
                <p>
                Backup Admin
                    <span> Welcome, {user && user?.userDetails} </span>
                    <span> <a href={`/logout`}>
                       | Logout
                    </a>
                    <a href="/customers"> | Customers </a>
                    <a href="/newaccount">| New Account </a>
                    <a href="/fullbackup">| Full Backup </a>
                    </span>
                </p>
            </div>
        )}
        </>
    )

}
export default NavBar;